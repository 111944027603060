<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('profile.title') }}
      </h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div class="
              w-20
              h-20
              sm:w-24 sm:h-24
              flex-none
              lg:w-32 lg:h-32
              image-fit
              relative
              m-auto
              cursor-pointer
            ">
            <div>
              <img alt="Icewall Tailwind HTML Admin Template" class="rounded-full"
                :src="require(`@/assets/images/${$f()[0].photos[0]}`)" />
              <div class="
                  absolute
                  mb-1
                  mr-1
                  flex
                  items-center
                  justify-center
                  bottom-0
                  right-0
                  bg-theme-action-1
                  rounded-full
                  p-2
                ">
                <CameraIcon class="w-4 h-4 text-white" />
              </div>
            </div>

            <input type="file" name="upload" style="position: absolute; top: 0%; opacity: 0 !important" class="
                cursor-pointer
                w-20
                h-20
                sm:w-24 sm:h-24
                flex-none
                lg:w-32 lg:h-32
                image-fit
                relative
                m-auto
                cursor-pointer
              " />
          </div>
          <div class="grid grid-cols-12 mt-5">
            <div class="lg:col-span-4"></div>
            <div class="lg:col-span-4 col-span-12">
              <input id="crud-form-1" type="text" class="form-control w-full dark:bg-dark-9" v-model="form.firstName"
                :placeholder="i18n('profile.form.firstName')" />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <div class="lg:col-span-4"></div>
            <div class="lg:col-span-4 col-span-12">
              <input id="crud-form-1" type="text" class="form-control w-full dark:bg-dark-9" v-model="form.lastName"
                :placeholder="i18n('profile.form.lastName')" />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <div class="lg:col-span-4"></div>
            <div class="lg:col-span-4 col-span-12">
              <input id="crud-form-1" type="text" class="form-control w-full dark:bg-dark-9" v-model="form.email"
                :placeholder="i18n('profile.form.emailAddress')" />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <div class="lg:col-span-4"></div>
            <div class="lg:col-span-4 col-span-12">
              <input id="crud-form-1" type="text" class="form-control w-full dark:bg-dark-9" v-model="form.phoneNumber"
                :placeholder="i18n('profile.form.phoneNumber')" />
            </div>
          </div>
          <div class="flex justify-center mt-5">
            <AppButton type="button" class="btn bg-theme-action-1 text-white cursor-pointer"
              :class="!saveLoading ? 'w-24' : ''" style="margin: 5px" :disabled="saveLoading || findLoading"
              :loading="saveLoading" @click="doSubmit()">
              <strong>{{ i18n('common.save') }}</strong>
              <template v-slot:loading>
                <span>{{ i18n('common.loading') }}</span>
                <LoadingIcon icon="three-dots" color="#FFFFFF" style="margin: 0 4px" />
              </template>
            </AppButton>
            <router-link class="btn bg-theme-action-2 text-black cursor-pointer" style="margin: 5px"
              :to="`/change-password`">
              <I18n code="profile.changePassword"></I18n>
            </router-link>
            <button type="button" class="btn bg-theme-action-2 text-black w-24 cursor-pointer" style="margin: 5px"
              @click="doCancel()">
              {{ i18n('common.cancel') }}
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
    <ErrorNotification :message="errorMessage" />
    <SuccessNotification message="profile.form.success" />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import ErrorNotification from '@/components/notifications/error-notification.vue'
import SuccessNotification from '@/components/notifications/success-notification.vue'
import Toastify from 'toastify-js'

export default defineComponent({
  components: {
    ErrorNotification,
    SuccessNotification
  },
  setup() {
    const errorMessage = ref('')
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: -1,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    const successNotificationToggle = () => {
      Toastify({
        node: cash('#success-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: -1,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    return {
      errorMessage,
      errorNotificationToggle,
      successNotificationToggle
    }
  },

  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        avatar: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      saveLoading: 'auth/loadingUpdateProfile',
      isRTL: 'layout/isRTL'
    })
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.profile')
    this.form = {
      firstName: this.currentUser.firstName,
      lastName: this.currentUser.lastName,
      email: this.currentUser.email,
      phoneNumber: this.currentUser.phoneNumber,
      avatar: this.currentUser.avatar
      // pagesAccess: [],
      // lang: ''
    }
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.profile')
    }
  },
  methods: {
    ...mapActions({
      doUpdateProfile: 'auth/doUpdateProfile'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      for (var key in this.form) {
        var value = this.form[key]
        if (value.length == 0) {
          this.errorMessage = 'profile.form.errors.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.push('/')
      // this.$router.back()
    },
    doSubmit() {
      if (this.isFormValid()) {
        this.successNotificationToggle()
        // this.$router.push('/home/product-view-page')
      } else {
        setTimeout(() => {
          this.errorNotificationToggle()
        }, 100)
      }
    }
  }
})
</script>
